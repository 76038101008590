/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Google Fonts
import "../css/google-fonts.css";

// import css
import "@fancyapps/fancybox/dist/jquery.fancybox.css";
import "../css/bootstrap-datepicker.standalone.min.css";
import "../css/magnific-popup.css";
import "../css/dependent-dropdown.min.css";
import "../css/app.css";
import "../css/custom.css";
import "../css/custom-2.css";
import "../css/mmenu.min.css";

// import js
import "@fancyapps/fancybox";
import "../js/bootstrap-datepicker";
import "../js/jquery.main";
import "../js/lazysizes.min";
import "../js/tabs";
import "../js/jquery.magnific-popup.min";
import "../js/masonry.pkgd.min";
import "../js/imagesloaded.pkgd.min";
import "../js/jquery-bridget";
import "../js/isotope.pkgd.min";
import "../js/dependent-dropdown.min";
import "../js/mmenu";
import "../js/custom";
import "../js/custom-2";
import "../js/google-places";
import "../js/mega-form";
import "../js/tracking";

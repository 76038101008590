// validation
jQuery('#sale-type').change(function () {
    if (jQuery(this).val() !== '') {
        jQuery('#AddressPersonPOSTCODE_9').prop('required', true);
        jQuery('#urgent_9').prop('required', true);
    } else {
        jQuery('#AddressPersonPOSTCODE_9').prop('required', false);
        jQuery('#urgent_9').prop('required', false);
    }
});

jQuery('#OpportunityPRODUCT_ID_11').change(function () {
    if (jQuery(this).val() !== '') {
        jQuery('#mods-urgent').prop('required', true);
    } else {
        jQuery('#mods-urgent').prop('required', false);
    }
});

// show date field
jQuery('.moving-date .radio-group input[type="radio"]').click(function () {
    if (jQuery('#moving-date-set').is(":checked")) {
        jQuery('.set-dates input, .set-dates img').show();
        jQuery('.set-dates input[type="hidden"]').prop("disabled", false);

        // set up datepicker of forms
        jQuery('.dates #usr1').datepicker({
            'format': 'yyyy-mm-dd',
            'autoclose': true
        }).on('changeDate', function (e) {
            var year = e.date.getFullYear();
            var month = e.date.getMonth() + 1;
            var day = e.date.getDate();

            jQuery('#OpportunityMOVE_DATE_12Month').val(month);
            jQuery('#OpportunityMOVE_DATE_12Day').val(day);
            jQuery('#OpportunityMOVE_DATE_12Year').val(year);
        });
    } else {
        jQuery('.set-dates input, .set-dates img').hide();
        jQuery('.set-dates input[type="hidden"]').prop("disabled", true);
    }
});

// show month field
jQuery('.store .radio-group input[type="radio"]').click(function () {
    if (jQuery('#store-yes').is(":checked")) {
        jQuery('#OpportunitySTORE_CONTAINER_MONTHS_ID_12').show();
        jQuery('#OpportunitySTORE_CONTAINER_MONTHS_ID_12').prop("disabled", false);
        jcf.refreshAll();
    } else {
        jQuery('#OpportunitySTORE_CONTAINER_MONTHS_ID_12').hide();
        jQuery('#OpportunitySTORE_CONTAINER_MONTHS_ID_12').prop("disabled", true);
        jcf.refreshAll();
    }
});

// form js
require("jquery-ui/ui/widgets/autocomplete");
/*
jQuery(function ($) {

    $('#AddressPersonPOSTCODE_9').on('input', function (e) {

        $('#AddressPersonPOSTCODE_9').autocomplete({

            minLength: 3,

            dataType: "json",

            open: function() {
                $("ul.ui-menu").width( $(this).innerWidth() );
            },

            source: function (req, res) {

                $.ajax({

                    type: "GET",

                    contentType: "application/json; charset=utf-8",

                    url: 'https://myquotes.containertraders.com.au/WebMarketing/HttpHandlers/LookupHandler.ashx',

                    data: {'inputVal': $('#AddressPersonPOSTCODE_9').val()},

                    success: function (result) {
                        res(result);
                    }

                });

            },

            select: function (event, ui) {

                event.preventDefault();

                if (ui.item.SuburbId && ui.item.SuburbId.length > 0) {

                    $(this).val(ui.item.Suburb + ' ' + ui.item.State + ', ' + ui.item.PostCode);

                    var json = JSON.stringify({
                        id: ui.item.SuburbId,
                        state: ui.item.State,
                        code: ui.item.PostCode,
                        suburb: ui.item.Suburb
                    });

                    $('#location_AddressPersonPOSTCODE_9').val(json);

                }

            }

        }).autocomplete('instance')._renderItem = function (ul, item) {

            return $('<li>').append(item.Suburb + ' ' + item.State + ', ' + item.PostCode).appendTo(ul);

        };

    });

});
*/

/*
jQuery(function ($) {

    $('#AddressPersonPOSTCODE_10').on('input', function (e) {

        $('#AddressPersonPOSTCODE_10').autocomplete({

            minLength: 3,

            dataType: "json",

            open: function() {
                $("ul.ui-menu").width( $(this).innerWidth() );
            },

            source: function (req, res) {

                $.ajax({

                    type: "GET",

                    contentType: "application/json; charset=utf-8",

                    url: 'https://myquotes.containertraders.com.au/WebMarketing/HttpHandlers/LookupHandler.ashx',

                    data: {'inputVal': $('#AddressPersonPOSTCODE_10').val()},

                    success: function (result) {
                        res(result);
                    }

                });

            },

            select: function (event, ui) {

                event.preventDefault();

                if (ui.item.SuburbId && ui.item.SuburbId.length > 0) {

                    $(this).val(ui.item.Suburb + ' ' + ui.item.State + ', ' + ui.item.PostCode);

                    var json = JSON.stringify({
                        id: ui.item.SuburbId,
                        state: ui.item.State,
                        code: ui.item.PostCode,
                        suburb: ui.item.Suburb
                    });

                    $('#location_AddressPersonPOSTCODE_10').val(json);

                }

            }

        }).autocomplete('instance')._renderItem = function (ul, item) {

            return $('<li>').append(item.Suburb + ' ' + item.State + ', ' + item.PostCode).appendTo(ul);

        };

    });

});
*/
/*
jQuery(function ($) {

    $('#AddressPersonPOSTCODE_11').on('input', function (e) {

        $('#AddressPersonPOSTCODE_11').autocomplete({

            minLength: 3,

            dataType: "json",

            open: function() {
                $("ul.ui-menu").width( $(this).innerWidth() );
            },

            source: function (req, res) {

                $.ajax({

                    type: "GET",

                    contentType: "application/json; charset=utf-8",

                    url: 'https://myquotes.containertraders.com.au/WebMarketing/HttpHandlers/LookupHandler.ashx',

                    data: {'inputVal': $('#AddressPersonPOSTCODE_11').val()},

                    success: function (result) {
                        res(result);
                    }

                });

            },

            select: function (event, ui) {

                event.preventDefault();

                if (ui.item.SuburbId && ui.item.SuburbId.length > 0) {

                    $(this).val(ui.item.Suburb + ' ' + ui.item.State + ', ' + ui.item.PostCode);

                    var json = JSON.stringify({
                        id: ui.item.SuburbId,
                        state: ui.item.State,
                        code: ui.item.PostCode,
                        suburb: ui.item.Suburb
                    });

                    $('#location_AddressPersonPOSTCODE_11').val(json);

                }

            }

        }).autocomplete('instance')._renderItem = function (ul, item) {

            return $('<li>').append(item.Suburb + ' ' + item.State + ', ' + item.PostCode).appendTo(ul);

        };

    });

});
*/
/*
jQuery(function ($) {

    $('#AddressPersonPOSTCODE_12').on('input', function (e) {

        $('#AddressPersonPOSTCODE_12').autocomplete({

            minLength: 3,

            dataType: "json",

            open: function() {
                $("ul.ui-menu").width( $(this).innerWidth() );
            },

            source: function (req, res) {

                $.ajax({

                    type: "GET",

                    contentType: "application/json; charset=utf-8",

                    url: 'https://myquotes.containertraders.com.au/WebMarketing/HttpHandlers/LookupHandler.ashx',

                    data: {'inputVal': $('#AddressPersonPOSTCODE_12').val()},

                    success: function (result) {
                        res(result);
                    }

                });

            },

            select: function (event, ui) {

                event.preventDefault();

                if (ui.item.SuburbId && ui.item.SuburbId.length > 0) {

                    $(this).val(ui.item.Suburb + ' ' + ui.item.State + ', ' + ui.item.PostCode);

                    var json = JSON.stringify({
                        id: ui.item.SuburbId,
                        state: ui.item.State,
                        code: ui.item.PostCode,
                        suburb: ui.item.Suburb
                    });

                    $('#location_AddressPersonPOSTCODE_12').val(json);

                }

            }

        }).autocomplete('instance')._renderItem = function (ul, item) {

            return $('<li>').append(item.Suburb + ' ' + item.State + ', ' + item.PostCode).appendTo(ul);

        };

    });

});
*/

handleMultiForm('ct-new-sale-form', 'location_AddressPersonPOSTCODE_9', 'AddressPersonPOSTCODE_9', 'AddressPersonSTATE_9', 'AddressPersonCITY_9');
handleMultiForm('ct-new-hire-form', 'location_AddressPersonPOSTCODE_10', 'AddressPersonPOSTCODE_10', 'AddressPersonSTATE_10', 'AddressPersonCITY_10');
handleMultiForm('ct-new-mods-form', 'location_AddressPersonPOSTCODE_11', 'AddressPersonPOSTCODE_11', 'AddressPersonSTATE_11', 'AddressPersonCITY_11');
handleMultiForm('ct-new-move-form', 'location_AddressPersonPOSTCODE_12', 'AddressPersonPOSTCODE_12', 'AddressPersonSTATE_12', 'AddressPersonCITY_12');
handleMultiForm('ct-new-contact-form', '', '', '', '');

function handleMultiFormSubmit(jqForm) {
    jqForm.off('submit');

    jqForm.find('.form-row.submit .form-loading-submit').show();

    let form_id = jqForm.attr('id');
    if(form_id == 'ct-new-move-form') {
        let moveFrom = $('#MOVE_FROM_12').val();
        let moveTo = $('#MOVE_TO_12').val();
        let message = $('#OpportunityDESCRIPTION_12').val();
        let message_updated = 'Moving from: ' + moveFrom + ' Moving to: ' + moveTo + ' Message: ' + message;
        $('#OpportunityDESCRIPTION_12').val(message_updated);
    }

    jQuery.ajax({
        type: "POST",
        contentType: "application/json; charset=utf-8",
        url: '/json/save-form-locally',
        data: JSON.stringify(objectifyForm(jqForm)),
        success: function (data) {
            // console.log('AJAX SUCCESS', data);
        },
        error: function (jqXHR, statusText, err) {
            // console.log('AJAX ERROR', jqXHR, statusText, err);
        },
        complete: function (jqXHR, statusText) {
            // Submit to CRM anyway
            jqForm.submit();
        }
    });
}

function handleMultiForm(form_id, location_input_id, location_hidden_postcode_id, location_hidden_state_id, location_hidden_city_id){
    jQuery('#' + form_id).on('submit', function (e) {

        e.preventDefault();
        e.returnValue = false;
        debugger;

        if(location_input_id !== '') {
            var locationData = document.getElementById(location_input_id).value;

            var locationObj = (locationData && locationData.length > 0) ? JSON.parse(locationData) : {};

            if (locationObj.id && locationObj.id.length > 0) {
                document.getElementById(location_hidden_postcode_id).value = locationObj.code;
                document.getElementById(location_hidden_state_id).value = locationObj.state;
                document.getElementById(location_hidden_city_id).value = locationObj.suburb;
            }
        }

        var recaptchaElement = $(this).find('.g-recaptcha');
        if (recaptchaElement.length > 0) {
            var widget_id = getWidgetId('recaptcha-' + form_id);
            var response = grecaptcha.getResponse(widget_id);
            var errorMessages = $(this).find('.g-recaptcha-error-message');
            var recaptchaErrorMessage =errorMessages.first();

            if (response.length === 0) {
                recaptchaErrorMessage.show();
                return false;
            } else {
                recaptchaErrorMessage.hide();
                handleMultiFormSubmit(jQuery(this));
            }
        }

    });
}

/**
 * Returns widgetId from a given element id
 * @param {string} elementId
 * @return {number} i
 */
function getWidgetId(elementId) {
    const recaptchaBoxes = document.querySelectorAll('.g-recaptcha');
    const targetBox = document.querySelector(`#${elementId}`);
    for (let i = 0; i < recaptchaBoxes.length; i++) {
        if (recaptchaBoxes[i].id === targetBox.id) {
            return i;
        }
    }
}


function objectifyForm(jqForm) {
    var formArray = jqForm.serializeArray();
    var returnObj = {};
    for (var i = 0; i < formArray.length; i++){
        returnObj[formArray[i]['name']] = formArray[i]['value'];
    }
    return returnObj;
}

jQuery('form.save-locally').on('submit', function (e) {
    e.preventDefault();
    e.returnValue = false;

    handleMultiFormSubmit(jQuery(this));
});

jQuery('form.save-locally-v2').on('submit', function (e) {
    e.preventDefault();
    e.returnValue = false;

    // var formId = $(this).attr('id');
    // var element_id = 'recaptcha-' + formId;
    // var widget_id = getWidgetId(element_id);
    var recaptchaElement = $(this).find('.g-recaptcha');
    if (recaptchaElement.length > 0) {
        // var response = grecaptcha.getResponse(widget_id);
        var response = grecaptcha.getResponse();
        var errorMessages = $(this).find('.g-recaptcha-error-message');
        var recaptchaErrorMessage =errorMessages.first();

        if (response.length === 0) {
            recaptchaErrorMessage.show();
            return false;
        } else {
            recaptchaErrorMessage.hide();
            handleMultiFormSubmit(jQuery(this));
        }
    } else {
        handleMultiFormSubmit(jQuery(this));
    }

});

jQuery('form.save-locally-v3').on('submit', function (e) {
    e.preventDefault();
    e.returnValue = false;

    var formId = $(this).attr('id');
    var element_id = 'recaptcha-' + formId;
    var widget_id = getWidgetId(element_id);
    var recaptchaElement = $(this).find('.g-recaptcha');
    if (recaptchaElement.length > 0) {
        var response = grecaptcha.getResponse(widget_id);
        // var response = grecaptcha.getResponse();
        var errorMessages = $(this).find('.g-recaptcha-error-message');
        var recaptchaErrorMessage =errorMessages.first();

        if (response.length === 0) {
            recaptchaErrorMessage.show();
            return false;
        } else {
            recaptchaErrorMessage.hide();
            handleMultiFormSubmit(jQuery(this));
        }
    } else {
        handleMultiFormSubmit(jQuery(this));
    }

});
jQuery(document).ready(function () {
    /* hire page */
    $('.custom-list ul li').each(function( index ) {
        let text = $( this ).text();
        $(this).html('<span><svg class="hire-checkmark"><use xlink:href="/images/sprite.svg#icon-checkmark"></use></svg></span><span>' + text + '</span>');
    });

    //removals location
    $('#select-removal-location-from').on('keyup', function(e) {
        var location = $('#select-removal-location-from').val();
        if(location.length < 3) {
            $('.location-from-list').slideUp();
            return;
        }
        var url = '/suburbs-suggestion/?location=' + location;
        $.ajax({
            url: url,
            type: 'get',
            success: function( result ){
                $('.location-from-list').html(result);
                $('.location-from-list').slideDown('slow');
            },
            error: function( jqXhr, textStatus, errorThrown ){
                //TODO
            }
        });
    });

    $('.location-from-list').on('click', '.suburb-select', function(e) {
        var name = $(this).attr('value');
        var slug = $(this).attr('slug');
        $('#select-removal-location-from').val(name);
        $('#select-removal-location-from').attr('slug', slug);
        $('.location-wrapper .location-from-list').slideUp();
    });

    $('#btn-location-from').on('click', function(e) {
        var url = '/shipping-containers/removals/';
        var from = $('#select-removal-location-from').attr('slug');
        var to = $('#select-removal-location-from').attr('to');
        if(from.length == 0) return;
        if(to.length == 0) return;
        // from = slugify(from);
        // to = slugify(to);
        var location = from + '-to-' + to;
        url = url + location;
        window.location.href = url;
    });

    $('#select-removal-location-to').on('keyup', function(e) {
        var location = $('#select-removal-location-to').val();
        if(location.length < 3) {
            $('.location-from-list').slideUp();
            return;
        }
        var url = '/suburbs-suggestion/?location=' + location;
        $.ajax({
            url: url,
            type: 'get',
            success: function( result ){
                $('.location-to-list').html(result);
                $('.location-to-list').slideDown('slow');
            },
            error: function( jqXhr, textStatus, errorThrown ){
                //TODO
            }
        });
    });

    $('.location-to-list').on('click', '.suburb-select', function(e) {
        var name = $(this).attr('value');
        var slug = $(this).attr('slug');
        $('#select-removal-location-to').val(name);
        $('#select-removal-location-to').attr('slug', slug);
        $('.location-wrapper .location-to-list').slideUp();
    });

    $('#btn-location-to').on('click', function(e) {
        var url = '/shipping-containers/removals/';
        var to = $('#select-removal-location-to').attr('slug');
        var from = $('#select-removal-location-to').attr('from');
        if(from.length == 0) return;
        if(to.length == 0) return;
        // from = slugify(from);
        // to = slugify(to);
        var location = from + '-to-' + to;
        url = url + location;
        window.location.href = url;
    });

    $('#select-removal-between-from').on('keyup', function(e) {
        var location = $('#select-removal-between-from').val();
        if(location.length < 3) {
            $('.location-between-list').slideUp();
            return;
        }
        var url = '/suburbs-suggestion/?location=' + location;
        $.ajax({
            url: url,
            type: 'get',
            success: function( result ){
                $('.location-between-from-list').html(result);
                $('.location-between-from-list').slideDown('slow');
            },
            error: function( jqXhr, textStatus, errorThrown ){
                //TODO
            }
        });
    });

    $('#select-removal-between-to').on('keyup', function(e) {
        var location = $('#select-removal-between-to').val();
        if(location.length < 3) {
            $('.location-between-to-list').slideUp();
            return;
        }
        var url = '/suburbs-suggestion/?location=' + location;
        $.ajax({
            url: url,
            type: 'get',
            success: function( result ){
                $('.location-between-to-list').html(result);
                $('.location-between-to-list').slideDown('slow');
            },
            error: function( jqXhr, textStatus, errorThrown ){
                //TODO
            }
        });
    });

    $('.location-between-from-list').on('click', '.suburb-select', function(e) {
        var name = $(this).attr('value');
        var slug = $(this).attr('slug');
        $('#select-removal-between-from').val(name);
        $('#select-removal-between-from').attr('from', slug);
        $('.location-wrapper .location-between-from-list').slideUp();
    });

    $('.location-between-to-list').on('click', '.suburb-select', function(e) {
        var name = $(this).attr('value');
        var slug = $(this).attr('slug');
        $('#select-removal-between-to').val(name);
        $('#select-removal-between-to').attr('to', slug);
        $('.location-wrapper .location-between-to-list').slideUp();
    });

    $('#btn-location-between').on('click', function(e) {
        var url = '/shipping-containers/removals/';
        var from = $('#select-removal-between-from').attr('from');
        var to = $('#select-removal-between-to').attr('to');
        if(from.length == 0) return;
        if(to.length == 0) return;
        var location = from + '-to-' + to;
        url = url + location;
        window.location.href = url;
    });

    //home page video
    $(".home-video-play, .footer-video-play, .collection-video-play").fancybox({
        openEffect: 'elastic',
        closeEffect: 'elastic',
        helpers: {
            title: {
                type: 'float'
            }
        }
    });

    //quote forms
    //$('.ct-toggle-on-page .ct-tab.active').trigger('click');
    //$('.ct-toggle-popup .ct-tab.active').trigger('click');

    //removal prices
    var city = $('.removal-price-table #desktop-price-table-select').val();
    $('.removal-price-table .desktop-price-table .price').hide();
    if(city) {
        if (city.length > 0) {
            $('.removal-price-table .desktop-price-table .' + city).show();
        } else {
            $('.removal-price-table .desktop-price-table .price').show();
        }
    } else {
        $('.removal-price-table .desktop-price-table .price').show();
    }

    $('.removal-price-table #desktop-price-table-select').on('change', function(){
        var city = $(this).val();
        $('.removal-price-table .desktop-price-table .price').hide();
        if(city.length > 0) {
            $('.removal-price-table .desktop-price-table .' + city).show();
        } else {
            $('.removal-price-table .desktop-price-table .price').show();
        }
    });

    city = $('.removal-price-table #mobile-movefrom').val();
    $('.removal-price-table .mobile-price-table .price').hide();
    if(city) {
        if (city.length > 0) {
            $('.removal-price-table .mobile-price-table .' + city).show();
        } else {
            $('.removal-price-table .mobile-price-table .price').show();
        }
    }
    $('.removal-price-table #mobile-movefrom').on('change', function(){
        var city = $(this).val();
        $('.removal-price-table .mobile-price-table .price').hide();
        if(city.length > 0) {
            $('.removal-price-table .mobile-price-table .' + city).show();
        } else {
            $('.removal-price-table .desktop-price-table').show();
        }
    });

    //removals quote location
    $('.removal-quote-form #movingfrom').on('keyup', function(e) {
        var location = $.trim($(this).val());
        if(location.length < 3) {
            $('.removal-quote-form .location-movingfrom-list').slideUp();
            return;
        }
        var url = '/suburbs-suggestion/?location=' + location;
        $.ajax({
            url: url,
            type: 'get',
            success: function( result ){
                $('.removal-quote-form .location-movingfrom-list').html(result);
                $('.removal-quote-form .location-movingfrom-list').slideDown('slow');
            },
            error: function( jqXhr, textStatus, errorThrown ){
                //TODO
            }
        });
    });

    $('.removal-quote-form .location-movingfrom-list').on('click', '.suburb-select', function(e) {
        var name = $(this).attr('value');
        $('.removal-quote-form #movingfrom').val(name);
        $('.removal-quote-form .location-movingfrom-list').slideUp();
    });

    $('.removal-quote-form #movingto').on('keyup', function(e) {
        var location = $.trim($(this).val());
        if(location.length < 3) {
            $('.removal-quote-form .location-movingto-list').slideUp();
            return;
        }
        var url = '/suburbs-suggestion/?location=' + location;
        $.ajax({
            url: url,
            type: 'get',
            success: function( result ){
                $('.removal-quote-form .location-movingto-list').html(result);
                $('.removal-quote-form .location-movingto-list').slideDown('slow');
            },
            error: function( jqXhr, textStatus, errorThrown ){
                //TODO
            }
        });
    });

    $('.removal-quote-form .location-movingto-list').on('click', '.suburb-select', function(e) {
        var name = $(this).attr('value');
        $('.removal-quote-form #movingto').val(name);
        $('.removal-quote-form .location-movingto-list').slideUp();
    });

    $('.removal-quote-form .btn-get-pricing').on('click', function(){

        $('.removal-quote-form .form-error').hide();

        var movingfrom = $.trim($('.removal-quote-form #movingfrom').val());
        var movingto = $.trim($('.removal-quote-form #movingto').val());
        var email = $.trim($('.removal-quote-form #moving-email').val());
        var name = $.trim($('.removal-quote-form #moving-name').val());

        var flag = true;
        if(movingfrom.length == 0) {
            flag = false;
            $('.removal-quote-form #movingfrom-error').show();
        }
        if(movingto.length == 0) {
            flag = false;
            $('.removal-quote-form #movingto-error').show();
        }
        if(email.length == 0) {
            flag = false;
            $('.removal-quote-form #email-error').show();
        }
        if(name.length == 0) {
            flag = false;
            $('.removal-quote-form #firstanme-error').show();
        }

        if(!flag) {
            return false;
        }

        return true;
    })

    //gallery page
    //https://github.com/desandro/masonry/issues/839
    //https://github.com/desandro/jquery-bridget
    var jQueryBridget = require('jquery-bridget');
    var Masonry = require('masonry-layout');
    // make Masonry a jQuery plugin
    $.bridget( 'masonry', Masonry, $ );
    var imagesLoaded = require('imagesloaded');
    if ($('.gallery-masonary-image.grid').length > 0) {
        imagesLoaded('.gallery-masonary-image.grid', function () {
            // images have loaded
            $('.gallery-masonary-image.grid').masonry({
                itemSelector: '.grid-item',
                percentPosition: true,
                gutter: 25
            });
        });
    }

    let gallery_load_page = 2;
    // GetGalleryImages();
    $('.section-container.gallery-load-more #bth-load-more').on('click', function(e){
        e.preventDefault();
        GetGalleryImages();
    })
    function GetGalleryImages() {
        let type = $('#gallery-media-type').find(":selected").val();

        const url = '/gallery-grid/'+ gallery_load_page + '?type=' + type;
        let data = {
            "page": gallery_load_page,
        }
        $.ajax({
            url: url,
            type: 'get',
            data: data,
            success: function (html) {
                var $content = $(html);
                 var next_page =  $(html).filter(".next-page").attr('next-page');
                 if(next_page == 0) {
                     $('.section-container.gallery-load-more').hide();
                 } else {
                     gallery_load_page = next_page;
                     $('.section-container.gallery-load-more').show();
                 }
                $('.section-container.gallery .gallery-masonary-image').append( $content ).masonry( 'appended', $content );
                imagesLoaded('.gallery-masonary-image.grid', function () {
                    // images have loaded
                    $('.section-container.gallery .gallery-masonary-image').masonry( 'reloadItems' );
                    $('.section-container.gallery .gallery-masonary-image').masonry( 'layout' );
                });
            }
        });
    }

    let delivery_gallery_load_page = 2;
    $('.section-container.gallery-load-more #bth-delivery-load-more').on('click', function(e){
        e.preventDefault();
        GetDeliveryGalleryImages();
    })
    function GetDeliveryGalleryImages() {
        const url = '/delivery-gallery-grid/'+ delivery_gallery_load_page;
        let data = {
            "page": delivery_gallery_load_page,
        }
        $.ajax({
            url: url,
            type: 'get',
            data: data,
            success: function (html) {
                var $content = $(html);
                var next_page =  $(html).filter(".next-page").attr('next-page');
                debugger;
                if(next_page == 0) {
                    $('.section-container.gallery-load-more').hide();
                } else {
                    delivery_gallery_load_page = next_page;
                }
                $('.section-container.gallery .gallery-masonary-image').append( $content ).masonry( 'appended', $content );
                imagesLoaded('.gallery-masonary-image.grid', function () {
                    // images have loaded
                    $('.section-container.gallery .gallery-masonary-image').masonry( 'reloadItems' );
                    $('.section-container.gallery .gallery-masonary-image').masonry( 'layout' );
                });
            }
        });
    }


    $('.gallery-masonary-image.modal-gallery').on('click', '.btn-get-quote-popup', function (e){
        e.preventDefault();

        var src = '#ct-mega-form';
        if($(this).hasClass('open-simple-form')){
            src = '#ct-simple-form';
            var crm_id = $(this).data('crm-id');
            var name = $(this).data('name');
            $(src).find('#sf-product-id').val(crm_id);
            $(src).find('#OpportunityDESCRIPTION_42').val('Please send me a quote regarding ' + name + '. ');
        }else if($('#ct-mega-form').length === 0){
            src = '#ct-simple-form';
        }

        $.magnificPopup.open({
            items: {
                src: src
            },
            type: 'inline'
        });

        dataLayer.push({
            'event': 'Mega-Quote_In_Modal'
        });
        gtag('config', 'UA-82642746-1', {
            'page_title': '',
            'page_path': '/quote-in-modal'
        });
    })

    $('#gallery-media-type').on('change', function(){
        gallery_load_page = 1;
        $('.section-container.gallery .gallery-masonary-image').html('');
        GetGalleryImages();
    });

    $('.get-quotes-form .form-control').on('click', function(){
        $(this).parent().parent().parent().find('.form-row').removeClass('mobile-hidden');
    })

    //Scrollup & Scrolldown
    var lastScrollTop = 0;
    $(window).scroll(function(event){
        var st = $(this).scrollTop();
        if (st > lastScrollTop){
            //scroll down
            $('header.sticky').removeClass('show');
            $('header.sticky').removeClass('hide-down');
            $('header.sticky').addClass('hide');
        } else {
            //scroll up
            $('header.sticky').addClass('show');
            $('header.sticky').removeClass('hide');
            $('header.sticky').removeClass('hide-down');
        }
        lastScrollTop = st;
    });

    $('#mobile-topbar-sticky .mobile-menu-close').on('click', function (){
        // $('header.sticky').removeClass('show');
        // $('header.sticky').removeClass('hide');
        // $('header.sticky').addClass('hide-down');
    })

    $('header#top .mobile-menu-trigger').on('click', function (){
        /*
        if($(this).hasClass('opening')) {
            $('#mobile-menu').addClass('hide');
            $('#mobile-menu').removeClass('show');
        } else {
            $('#mobile-menu').addClass('show');
            $('#mobile-menu').removeClass('hide');
        }
        */
        $('header#top').removeClass('initial');
    })

    /*
    $('#mobile-topbar-sticky .mobile-menu-close').on('click', function (){
        $('#mobile-menu').removeClass('show');
        $('#mobile-menu').addClass('hide');
    })
    */

    /*
    $('.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~.mm-slideout header#top .header-main .mobile-menu-trigger').on('click', function(){
        debugger;
        $('#mobile-topbar-sticky .mobile-menu-close').trigger('click');
    })
    */

    $('.mobile-menu-trigger .burger-item').on('click', function (e){
        e.preventDefault();
        let mobile_menu = $('#mobile-menu');
        if($(this).parent().hasClass('opening')) {
            $(this).parent().removeClass('opening');
            //$('#mobile-topbar-sticky .mobile-menu-close .burger-item').trigger('click');
            jw_mmenu.close();
            dataLayer.push({
                'event': 'Close_Mobile_Menu'
            });
            gtag('config', 'UA-82642746-1', {
                'page_title': '',
                'page_path': '/close-mobile-menu'
            });
            console.info('close mobile menu');
        } else {
            $(this).parent().addClass('opening');
            dataLayer.push({
                'event': 'Open_Mobile_Menu'
            });
            gtag('config', 'UA-82642746-1', {
                'page_title': '',
                'page_path': '/open-mobile-menu'
            });
            console.info('open mobile menu');
        }
    })

    $('#mobile-menu .mm-navbars_top').addClass('hide');
    $('#mobile-menu .mm-btn_next').on('click', function(e){
        $('#mobile-menu .mm-navbars_top').removeClass('hide');
    })
    $('#mobile-menu .mm-btn_prev, #mobile-menu .mm-navbars_top .mm-navbar__title').on('click', function(e){
        let title = $("#mobile-menu .mm-navbars_top .mm-navbar__title").text();
        if ((title.toLowerCase() === "shipping containers") ||
            (title.toLowerCase() === "used shipping containers") ||
            (title.toLowerCase() === "new shipping containers") ||
            (title.toLowerCase() === "hire") ||
            (title.toLowerCase() === "mods") ||
            (title.toLowerCase() === "shelter") ||
            (title.toLowerCase() === "removals") ||
            (title.toLowerCase() === "our locations"))
        {
            $('#mobile-menu .mm-navbars_top').addClass('hide');
        }
    })

    /* background image lazy loading*/
    document.addEventListener('lazybeforeunveil', function(e){
        var bg = e.target.getAttribute('data-bg');
        if(bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });

    //community pages
    $('#btn-criteria').on('click', function(){

        let winWidth = $(window).width();
        if(winWidth > 700) {
            $('html, body').animate({
                scrollTop: $("#community-criteria").offset().top - 250
            }, 1000);
        } else {
            $('html, body').animate({
                scrollTop: $("#community-criteria").offset().top - 80
            }, 1000);
        }
    })

    let current_url = $(location).attr('href');
    if(current_url.indexOf('community-program#criteria') >= 0) {
        let winWidth = $(window).width();
        if (winWidth > 700) {
            $('html, body').animate({
                scrollTop: $("#community-criteria").offset().top - 1000
            }, 1000);
        } else {
            $('html, body').animate({
                scrollTop: $("#community-criteria").offset().top - 80
            }, 1000);
        }
    }

    //Show half of next slide without Center Mode in Slick Slider
    //https://stackoverflow.com/questions/41905363/show-half-of-next-slide-without-center-mode-in-slick-slider
    $('.community-support .support-slider-wrapper').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: true,
        arrows: true,
        dots: false,
        centerMode: true,
        arrows: false,
        lazyLoad: 'ondemand'
    });

    $('.support-slider-wrapper .body .right .next-button').on('click', function(){
        $('.community-support .support-slider-wrapper').slick('slickNext');
    })

    $('.community-support .support-features-wrapper .link').on('click', function (){
        let slug = $(this).attr('slug');
        let slide = $('.support-slider-wrapper .support-box#' + slug);
        if(slide.length > 0) {
            let slide_number = slide.attr('data-slick-index');
            $('.community-support .support-slider-wrapper').slick('slickGoTo', slide_number);

            let winWidth = $(window).width();
            if(winWidth > 700) {
                $('html, body').animate({
                    scrollTop: $("#support-slider-wrapper").offset().top - 250
                }, 1000);
            } else {
                $('html, body').animate({
                    scrollTop: $("#support-slider-wrapper").offset().top - 80
                }, 1000);
            }
        }
    })

    $('#eligible-checkmark').on('click', function (){
        if ($('#eligible').is(":checked")) {
            console.log('checked');
        } else {
            console.log('unchecked');
        }
    });

    var jQueryBridget = require('jquery-bridget');
    var Isotope = require('isotope-layout');
    // make Isotope a jQuery plugin
    $.bridget( 'isotope', Isotope, $ );

    /*
    $('.product-isotope-grid').isotope({
        // options
        itemSelector: '.product-container',
        layoutMode: 'fitRows',
    });
    */

});

// masonry layout
if ($('.masonary-image.grid').length > 0) {
    var imagesLoaded = require('imagesloaded');
    imagesLoaded('.masonary-image.grid', function () {
        // images have loaded
        var Masonry = require('masonry-layout');

        var msnry = new Masonry('.masonary-image.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
            gutter: 25
        });
    });
}

if ($('.masonary-image.grid_2').length > 0) {
    var imagesLoaded = require('imagesloaded');
    imagesLoaded('.masonary-image.grid_2', function () {
        // images have loaded
        var Masonry = require('masonry-layout');

        var msnry = new Masonry('.masonary-image.grid_2', {
            itemSelector: '.grid-item',
            percentPosition: true,
            gutter: 25
        });
    });
}

if ($('.masonary-image.grid_3').length > 0) {
    var imagesLoaded = require('imagesloaded');
    imagesLoaded('.masonary-image.grid_3', function () {
        // images have loaded
        var Masonry = require('masonry-layout');

        var msnry = new Masonry('.masonary-image.grid_3', {
            itemSelector: '.grid-item',
            percentPosition: true,
            gutter: 25
        });
    });
}

if ($('.masonary-image.grid_4').length > 0) {
    var imagesLoaded = require('imagesloaded');
    imagesLoaded('.masonary-image.grid_4', function () {
        // images have loaded
        var Masonry = require('masonry-layout');

        var msnry = new Masonry('.masonary-image.grid_4', {
            itemSelector: '.grid-item',
            percentPosition: true,
            gutter: 25
        });
    });
}

//lazy loading
/*$(function() {
    $('.lazy').lazy({
        effect: 'fadeIn',
        delay: 1000,
        visibleOnly: true,
        onError: function(element) {
            console.log('error loading ' + element.data('src'));
        }
    });

*/

//add simple support for background images:
document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

//Search form
$('#searchButton').on('click', function(e) {
    e.preventDefault();
    var searchTerm = $('#searchContent').val();
    var searchLocation = $('#select-location-to').val();

    if(searchTerm.length == 0) {
        return false;
    }

    var googleSearchTerm = searchTerm;
    if(searchLocation.length > 0) {
        googleSearchTerm += ' AND ' + searchLocation;
    }

    $('#googleSearchForm #gsc-i-id1').val(googleSearchTerm);
    $('#googleSearchForm .gsc-search-button').trigger('click');

})

if ($('#google-custom-search-result').length) {
    $('#top .header-nav-search-wrapper').addClass('active');
}

/* site verify */
$('#btn-download-price-guide').click(function (e) {
    e.preventDefault();

    $('.price-guide-form .form-error').hide();
    var firstname = $.trim($('.price-guide-form #firstname').val());
    var lastname = $.trim($('.price-guide-form #lastname').val());
    var phone = $.trim($('.price-guide-form #phone').val());
    var email = $.trim($('.price-guide-form #email').val());
    var flag = true;
    if(firstname.length == 0) {
        flag = false;
        $('.price-guide-form #price-guide-fname-error').show();
    }
    if(lastname.length == 0) {
        flag = false;
        $('.price-guide-form #price-guide-lname-error').show();
    }
    if(phone.length == 0) {
        flag = false;
        $('.price-guide-form #price-guide-phone-error').show();
    }
    if(email.length == 0) {
        flag = false;
        $('.price-guide-form #price-guide-email-error').show();
    }
    if(!flag) {
        return false;
    }

    var url = '/site-verify';
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $('form#price-guide-form').serialize(),
        success: function (data) {
            if (data.result == 'success') {
                $('form#price-guide-form').submit();
            } else {
                //TODO
            }
        }
    });
});


//ask a question
$('.question_form_error').hide();
$('#btn-ask-a-question').click(function (e) {
    e.preventDefault();
    $('.question_form_error').hide();
    var firstname = $('#ask-a-question-form #first-name').val();
    // var lastname = $('#ask-a-question #last-name').val();
    var email = $('#ask-a-question-form #email').val();
    var question = $('#ask-a-question-form #question').val();
    var error = false;
    if(firstname.length == 0) {
        $('#first_name_error').show();
        error = true;
    }
    if(email.length == 0) {
        $('#email_error').show();
        error = true;
    }
    if(question.length == 0) {
        $('#question_error').show();
        error = true;
    }
    if(error) { return false; }

    var url = '/site-verify';
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $('form#ask-a-question-form').serialize(),
        success: function (data) {
            if (data.result == 'success') {
                $('form#ask-a-question-form').submit();
            } else {
                //TODO
            }
        }
    });
});

$('#btn-download-removals-ebook').click(function (e) {
    e.preventDefault();

    $('.ebook-download-wrapper .form-error').hide();
    var firstname = $.trim($('.ebook-download-wrapper #ebook-fname').val());
    var phone = $.trim($('.ebook-download-wrapper #ebook-phone').val());
    var email = $.trim($('.ebook-download-wrapper #ebook-email').val());

    var flag = true;
    if(firstname.length == 0) {
        flag = false;
        $('.ebook-download-wrapper #ebook-fname-error').show();
    }
    if(phone.length == 0) {
        flag = false;
        $('.ebook-download-wrapper #ebook-phone-error').show();
    }
    if(email.length == 0) {
        flag = false;
        $('.ebook-download-wrapper #ebook-email-error').show();
    }

    if(!flag) {
        return false;
    }

    var url = '/site-verify';
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $('form#download-removals-ebook-form').serialize(),
        success: function (data) {
            if (data.result == 'success') {
                $('form#download-removals-ebook-form').submit();
            } else {
                //TODO
            }
        }
    });
});

$('#btn-removals-price-guide').click(function (e) {
    e.preventDefault();
    var url = '/site-verify';
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $('form#removals-price-guide-form').serialize(),
        success: function (data) {
            if (data.result == 'success') {
                $('form#removals-price-guide-form').submit();
            } else {
                //TODO
            }
        }
    });
});

//contact us
/*$('.contact_us_form_error').hide();
$('#btn-contact-us').click(function (e) {
    e.preventDefault();
    $('.contact_us_form_error').hide();
    var firstname = $('#contact-us-form #first-name').val();
    var email = $('#contact-us-form #email').val();
    var message = $('#contact-us-form #message').val();
    var error = false;
    if(firstname.length == 0) {
        $('#first_name_error').show();
        error = true;
    }
    if(email.length == 0) {
        $('#email_error').show();
        error = true;
    }
    if(message.length == 0) {
        $('#message_error').show();
        error = true;
    }
    if(error) { return false; }

    var url = '/site-verify';
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $('form#contact-us-form').serialize(),
        success: function (data) {
            if (data.result == 'success') {
                $('form#contact-us-form').submit();
            } else {
                //TODO
            }
        }
    });
});*/

$(".scroll-parallax-2").paroller({
    factor: 0.001,
    factorXs: 0, // multiplier for scrolling speed and offset if window width is <576px
    factorSm: 0, // multiplier for scrolling speed and offset if window width is <=768px
    factorMd: 0, // multiplier for scrolling speed and offset if window width is <=1024px
    factorLg: 0, // multiplier for scrolling speed and offset if window width is <=1200px
    type: 'foreground', // background, foreground
    direction: 'vertical', // vertical, horizontal
    transition: 'translate .1s ease' // CSS transition, added on elements where type:'foreground'
});

/* delivery page carousel */
$('.delivery-carousel-tab-heading a').on('click', function () {
    $('.delivery-carousel-tab-heading a').removeClass('active');
    $(this).addClass('active');
    var tab_id = $(this).data('tab');
    $(this).parents('.delivery-carousel-tab-heading').siblings('.delivery-carousel-tab-content').children('li').each(function () {
        var content_tab_id = $(this).data('tab');

        if (content_tab_id == tab_id) {
            $(this).addClass('active').addClass('animate__animated').addClass('animate__fadeIn');
        } else {
            $(this).removeClass('active').removeClass('animate__animated').removeClass('animate__fadeIn');
        }
    });
});

if($(window).scrollTop() > 1) {
    $('#BackToTopBtn').show();
} else {
    $('#BackToTopBtn').hide();
}
$(window).scroll(function () {
    if($(window).scrollTop() > 1) {
        $('#BackToTopBtn').show();
    } else {
        $('#BackToTopBtn').hide();
    }
});

$('#BackToTopBtn').on('click', function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
});

$('#product-list .navigation a').on('click', function(e) {
   e.preventDefault();
   var el = $(this).attr('href');
    $('html, body').animate({
        scrollTop: $(el).offset().top
    }, 800);
    return false;
});

jQuery(function($){
	//quote form modal window
$('.btn-get-quote-popup').on('click', function(e){
    //debugger;
    e.preventDefault();
	//console.log('testssss');

    var src = '#ct-mega-form';
    if($(this).hasClass('open-simple-form')){
        src = '#ct-simple-form';
        var crm_id = $(this).data('crm-id');
        var name = $(this).data('name');
        $(src).find('#sf-product-id').val(crm_id);
        $(src).find('#OpportunityDESCRIPTION_42').val('Please send me a quote regarding ' + name + '. ');
    }else if($('#ct-mega-form').length === 0){
        src = '#ct-simple-form';
    }

    $.magnificPopup.open({
        items: {
            src: src
        },
        type: 'inline'
    });

    dataLayer.push({
        'event': 'Mega-Quote_In_Modal'
    });
    gtag('config', 'UA-82642746-1', {
        'page_title': '',
        'page_path': '/quote-in-modal'
    });
});
});






$('#load-quote-form-popup .close').on('click', function(){
    $('#load-quote-form-popup').hide('slow');
});

//download brochure modal window
$('.btn-download-brochure-popup').on('click', function(e){
    e.preventDefault();
    let download_form = $(this).attr('download_form');
    $('#load-brochure-'+ download_form + '-form-popup').show('slow');
    $('#load-brochure-'+ download_form + '-form-popup .ct-panel').show();

    dataLayer.push({
        'event': 'Mega-Quote_In_Modal'
    });
    gtag('config', 'UA-82642746-1', {
        'page_title': '',
        'page_path': '/quote-in-modal'
    });

    console.log("quote-in-modal");
})

$('.brochure-download-popup .close').on('click', function(){
    $(this).parent().parent().hide('slow');
});

/* custom content */
$('.custom-content h2').each(function( index ) {
    $(this).addClass('h1');
});

$('.custom-content.about-us-introduction h2').each(function( index ) {
    $(this).addClass('about-us');
});

$('.custom-content li').each(function( index ) {
    $(this).prepend('<i class="icon-tick"></i>');
});

$('.thank-you-wrapper li').each(function( index ) {
    $(this).prepend('<i class="icon-check2"></i>');
});

// dependent dropdown
handle_dependent_dropdown('sale-depdrop', "sale-size", "sale-type", 'sale-state');

function handle_dependent_dropdown(depdrop, size, type, state) {
    var dep1 = state;
    var dep2 = size;
    depdrop = jQuery('.' + depdrop);
    size = jQuery('#' + size);
    type = jQuery('#' + type);

    size.depdrop({
        depends: [dep1],
        url: '/json/get-prod-size',
        initialize: false,
        placeholder: 'Select a Size',
        loadingText: 'Loading ...',
        skipDep: true,
        ajaxSettings: {
            type: 'POST',
            data: {
                'sale-type': depdrop.parents('form').find('input[name="sale-type"]').val(),
            }
        }
    });

    // refresh other select dropdowns
    depdrop.on('depdrop:beforeChange', function (event, id, value, jqXHR, textStatus) {
        //console.log('depdrop:beforeChange');
        $('.form-loading').css('display', 'flex');
        jcf.replaceAll();
    });
    depdrop.on('depdrop:afterChange', function (event, id, value, jqXHR, textStatus) {
        //console.log('depdrop:afterChange');
        $('.form-loading').hide();
        jcf.replaceAll();
    });

    type.depdrop({
        depends: [dep1, dep2],
        url: '/json/get-prod-type',
        initialize: false,
        placeholder: 'Select a Type',
        loadingText: 'Loading ...',
        emptyMsg: 'Select others first...',
        skipDep: true,
        ajaxSettings: {
            type: 'POST',
            data: {
                'sale-type': depdrop.parents('form').find('input[name="sale-type"]').val(),
            }
        }
    });
}

//brochures
$('.brochure-slick-wrapper .brochure_cards_wrapper').slick({
    centerMode: false,
    slidesToShow: 2,
    arrows: true,
    prevArrow: "<span class='icon-chevron-left'></span>",
    nextArrow: "<span class='icon-chevron-right'></span>",
    lazyLoad: 'ondemand',
    responsive: [{
        breakpoint: 1199,
        settings: {
            slidesToShow: 1,
            centerMode: false,
            arrows: true,
            prevArrow: "<span class='icon-chevron-left'></span>",
            nextArrow: "<span class='icon-chevron-right'></span>"
        }
    }]
});

//about us page hero slider
$('.about-hero .slider-wrapper').slick({
    autoplay: true,
    autoplaySpeed: parseInt($('#about_us_slider_speed').text()),
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    dots: true,
});

//footer slider
$('.footer .footer-slider-wrapper').slick({
    centerMode: true,
    centerPadding: '20%',
    autoplay: true,
    autoplaySpeed: parseInt($('#footer_slider_speed').text()),
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    dots: false,
    prevArrow: "<span class='icon-chevron-left slick-arrow'></span>",
    nextArrow: "<span class='icon-chevron-right slick-arrow'></span>",
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 1,
            centerMode: false,
        }
    }]
});


//product panel on collection page
$('.product-content').each(function () {
    var for_slider = $(this).find('.for-slider');

    $(this).find('.product-image').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        draggable: false,
        lazyLoad: 'ondemand',
        responsive: [{
            breakpoint: 600,
            settings: {
                // centerMode: true,
                // centerPadding: '40px'
                autoplay: true,
                autoplaySpeed: 2000,
            }
        }]
    });
    $(this).find('.product-image-thumbs').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        asNavFor: for_slider,
        draggable: false,
        focusOnSelect: true,
        prevArrow: "<span class='icon-chevron-left'></span>",
        nextArrow: "<span class='icon-chevron-right'></span>"
    });
});

//click to call/mail
$('a').click(function () {
    var hrefs = jQuery(this).attr("href");
    if (hrefs.includes('mailto:')) {
        gtag('config', 'UA-82642746-1', {
            'page_title': '',
            'page_path': '/click-to-mail'
        });
        console.log("click-to-mail");
    }else if(hrefs.includes('tel:')){
        gtag('config', 'UA-82642746-1', {
            'page_title': '',
            'page_path': '/click-to-call'
        });
        console.log("click-to-call");

    }
});

$('.carousel-tab-heading-wrapper .carousel-nav-arrows-left').on('click', function(e){
    e.preventDefault();
    let totalTitle = $(this).next().find('li').length;
    let currentTitle = $(this).next().find('li a.active').parent();
    let currentIndex = $(this).next().find('li').index(currentTitle);
    let prevIndex = currentIndex - 1;
    if(prevIndex < 0) {
        prevIndex = totalTitle - 1;
    }
    $(this).next().find('li a').removeClass('active');
    $(this).next().find('li:eq(' + prevIndex + ')').find('a').addClass('active').trigger('click');
})

$('.carousel-tab-heading-wrapper .carousel-nav-arrows-right').on('click', function(e){
    e.preventDefault();
    let totalTitle = $(this).prev().find('li').length;
    let currentTitle = $(this).prev().find('li a.active').parent();
    let currentIndex = $(this).prev().find('li').index(currentTitle);
    let nextIndex = currentIndex + 1;
    if(nextIndex >= totalTitle) {
        nextIndex = 0;
    }
    $(this).prev().find('li a').removeClass('active');
    $(this).prev().find('li:eq(' + nextIndex + ')').find('a').addClass('active').trigger('click');
})

/* sticky header */
/*
var stickyNavTop = $('header#top .header-main').offset().top;
$(window).scroll(function () {
    if($(window).scrollTop() > stickyNavTop) {
        $("header#top").addClass('sticky');
        $(".breadcrumb-wrapper").addClass('sticky');
    } else {
        $("header#top").removeClass('sticky');
        $(".breadcrumb-wrapper").removeClass('sticky');
    }
});
*/

/*$('.mobile-menu-close').on('click', function(){
    debugger;
});*/
var jw_mmenu = new Mmenu("#mobile-menu", {
            "extensions": [
                "position-right"
            ],
            "navbars": [{
                "position": "top",
                "content": [
                    "prev",
                    "title",
                    "close"
                ]
            }]
        },{
            // configuration
            offCanvas: {
                page: {
                    selector: "#my-page"
                }
            }
        });
/*document.addEventListener(
    "DOMContentLoaded", () => {
       var api = jw_mmenu.API;
        api.bind( "open:start",
           function(){

               //console.log( "Started opening panel: " );
             
            }
        );

        api.bind( "close:start",
            function(){
                //console.log( "Close panel: " );
              
            }
        );

    }
);*/	
	

jQuery(function($){
	
	$('#mobile-topbar-sticky .mobile-menu-trigger.mobile-menu-close .burger-item').on('click', function(e){
		e.preventDefault();
   // debugger;
    //console.info("click 123");
	// console.log("tttt");
	jw_mmenu.close();
    //$('#mobile-menu .mm-btn_close').trigger('click');
});
})

$(window).on('load', function() {
    $('img.custom-lazyload').each(function( index ) {
        var src = $(this).data('srcc');
        $(this).attr("src",src);
    });
});

//container filters
$('.products-filter-wrapper #sizes, .products-filter-wrapper #types').on('change', function(e){
    debugger;
    var type = $('.products-filter-wrapper #types').val();
    var size = $('.products-filter-wrapper #sizes').val();
    $('#product-grid .product-container').addClass('hidden');
    $('#price-guide .pricing-table tbody tr').addClass('hidden');
    if(type.length == 0) {
        $('#product-grid .product-container').removeClass('hidden');
        $('#price-guide .pricing-table tbody tr').removeClass('hidden');
        if(e.target.id == 'types') {
            var size_select = $('.products-filter-wrapper #sizes');
            size_select.empty();
            var sizeOptions = {
                'Select...': ''
            };
            $.each(sizeOptions, function (key, value) {
                size_select.append($('<option></option>').attr('value', value).text(key));
            });
            size_select.val('');
            size_select.trigger('change');
        }
    } else {
        if(e.target.id == 'types') {
            var size_select = $('.products-filter-wrapper #sizes');
            size_select.empty();
            switch(type) {
                case "general-purpose-standard":
                case "high-cube":
                    var sizeOptions = {
                        'Select...':'',
                        '10ft':'10ft',
                        '20ft':'20ft',
                        '40ft':'40ft'
                    };
                    break;
                case "double-door":
                case "pallet-wide":
                case "side-opening":
                case "refrigerated":
                case "insulated":
                case "open-top":
                case "bolster":
                case "flat-rack":
                    var sizeOptions = {
                        'Select...':'',
                        '20ft':'20ft',
                        '40ft':'40ft'
                    };
                    break;
                case "dangerous-goods":
                    var sizeOptions = {
                        'Select...':'',
                        '10ft':'10ft',
                        '20ft':'20ft'
                    };
                    break;
                case "iso-tank":
                case "half-height":
                case "bulker":
                    var sizeOptions = {
                        'Select...':'',
                        '20ft':'20ft'
                    };
                    break;
                case "minicube":
                    var sizeOptions = {
                        'Select...':'',
                        '6ft':'6ft',
                        '8ft':'8ft'
                    };
                    break;
                default:
                    var sizeOptions = {
                        'Select...':''
                    };
            }
            $.each(sizeOptions, function(key, value) {
                size_select.append($('<option></option>').attr('value', value).text(key));
            });
            size_select.val('');
            size_select.trigger('change');
            $("#product-grid .product-container[class*='" + type + "']").removeClass('hidden');
            $("#price-guide .pricing-table tbody tr[class*='" + type + "']").removeClass('hidden');
        } else {
            if(size.length == 0) {
                $("#product-grid .product-container[class*='" + type + "']").removeClass('hidden');
                $("#price-guide .pricing-table tbody tr[class*='" + type + "']").removeClass('hidden');
            } else {
                $("#product-grid .product-container[class*='" + type + "'][class*='" + size + "']").removeClass('hidden');
                $("#price-guide .pricing-table tbody tr[class*='" + type + "'][class*='" + size + "']").removeClass('hidden');
            }
        }
    }
});

//collection page
$('#product-grid-new-link').on('click', function(e){
    e.preventDefault();
    $('html, body').animate({
        scrollTop: $("#product-grid-new").offset().top - 400
    }, 1000);
})

$('#product-grid-used-link').on('click', function(e){
    e.preventDefault();
    $('html, body').animate({
        scrollTop: $("#product-grid-used").offset().top - 500
    }, 1000);
})

$('#location-buy-icon').on('click', function(e){
    e.preventDefault();
    $('html, body').animate({
        scrollTop: $("#product-grid-new").offset().top - 400
    }, 1000);
})

$('#product-list .inner-navigation a').on('click', function(e) {
    e.preventDefault();
    var el = $(this).attr('href');
    $('html, body').animate({
        scrollTop: $(el).offset().top - 50
    }, 800);
    return false;
});

function isElementOutOfView($element) {
    const rect = $element[0].getBoundingClientRect();  // jQuery element access
    return (
        rect.bottom < 0 ||  // Scrolled above the viewport
        rect.top > $(window).height()  // Scrolled below the viewport
    );
}

// const inner_menu_sticky_start = $('#inner-menu-sticky-start');
const inner_menu = $('#inner-menu');
$(window).on('scroll', function() {
    if (isElementOutOfView(inner_menu)) {
        $('.sticky-wrapper').addClass('sticky');
    } else {
        $('.sticky-wrapper').removeClass('sticky');
    }
});





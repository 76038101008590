window.ga_event_track = function(event_URL,event_title){
    if(window.location.hostname === "containertraders.com.au" && true){
        gtag('config', 'UA-82642746-1', {'page_path': event_URL});
        console.log('URL: '+event_URL+'| Title: '+event_title);
    }else{
        console.log('URL: '+event_URL+'| Title: '+event_title);
    }
}

jQuery(document).ready(function () {
    // $("a[href^='tel:']").click( function() {
    //     ga_event_track('/click-to-call', 'Click to Call');
    // });

    $("a.btn-get-quote-popup").click( function() {
        dataLayer.push({
            'event': 'Quick_Quote_Open'
        });
        ga_event_track('/quick-quote-open/','Quick Quote Open');
    });
});
// tabs
(function() {
    this.Toggle = (function() {
        Toggle.prototype.el = null;

        Toggle.prototype.tabs = null;

        Toggle.prototype.panels = null;

        function Toggle(toggleClass) {
            this.el = $(toggleClass);
            this.tabs = this.el.find(".ct-tab");
            this.panels = this.el.find(".ct-panel");
            this.bind();
        }

        Toggle.prototype.show = function(index) {
            var activePanel, activeTab;
            this.tabs.removeClass('active');
            activeTab = this.tabs.get(index);
            $(activeTab).addClass('active');
            this.panels.hide();
            activePanel = this.panels.get(index);
            return $(activePanel).show();
        };

        Toggle.prototype.bind = function() {
            var _this = this;
            return this.tabs.unbind('click').bind('click', function(e) {
                return _this.show($(e.currentTarget).index());
            });
        };

        return Toggle;

    })();
}());